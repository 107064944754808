import React from "react";

export const SOFTWARE_URLS = [
  {
    name: "KW_TRACKER_EXT",
    shortName: "kwtext",
    link: "https://chrome.google.com/webstore/detail/fgkhimnkbaccbhigaeoggfbmggdidjjj"
  },
  {
    name: "SCOUT_EXT",
    shortName: "scoutext",
    link: "https://chrome.google.com/webstore/detail/jjddacdiaghmhplanidommpeehflcphk"
  },
  {
    name: "SCOUT_EXT_PRO",
    shortName: "proext",
    link: `${process.env.SCOUT_EXT_PRO}`
  },
  {
    name: "SCOUT_EXT_NEW",
    shortName: "scoutextnew",
    link: "https://chrome.google.com/webstore/detail/jjddacdiaghmhplanidommpeehflcphk"
  },
  {
    name: "SCOUT_EXT_PRO_NEW",
    shortName: "proextnew",
    link: `${process.env.SCOUT_EXT_PRO_NEW}`
  },
  {
    name: "QUICK_VIEW_EXT",
    shortName: "qvext",
    link: "https://chrome.google.com/webstore/detail/pggamokfileohlopdonjmelbbghhnlah"
  },
  {
    name: "CALC_EXT",
    shortName: "calcext",
    link: "https://chrome.google.com/webstore/detail/dkgjopcolgcafhnicdahjemapkniikeh"
  },
  {
    name: "WEB",
    shortName: "webapp",
    link: `${process.env.HOST_NAME}/app/`
  }
];

export const ALLOWED_URLS = [
  "*.amzscout.net",
  "*.ctfassets.net",
  "*.google.com",
  "*.youtube.com",
  "goo.gl",
  "*.amazon.com",
  "*.amazon.co.uk",
  "*.amazon.ca",
  "*.amazon.de",
  "amazon.fr",
  ".amazon.it",
  ".amazon.es",
  "*.amazon.in",
  "*.amazon.com.mx",
  "*.amazon.com.au",
  "*.clickfunnels.com"
];

export const HINTS_DEFAULT = "We are loading something valuable for you, so we are redirecting you to the store";

export const HINTS = {
  en: {
    pdf: "Preparing a splendid PDF for you! Please, wait until it's loaded.",
    store: "Hold on! Redirecting you to the Chrome Store to install the app.",
    url: "Something splendid is getting ready. Hold tight!"
  },
  ru: {
    pdf: "Готовим для вас PDF. Пожалуйста, дождитесь его загрузки.",
    store: "Перенаправляем в Chrome Store для установки приложения.",
    url: "Загружаем материал! Осталось всего пара секунд."
  },
  de: {
    pdf: "Wir bereiten für Sie .PDF vor, Bitte, warten Sie dessen Downloads ab!",
    store: "Es wird auf Chrome Store für Installation der Anwendung verlinkt",
    url: "Wir stellen den Inhalt ein! Nur noch ein paar Sekunden."
  },
  fr: {
    pdf: "Nous préparons le PDF pour vous. Veuillez-vous attendre son chargement.",
    store: "Nous vous redirigeons vers le Chrome Store pour l'installation de l'application.",
    url: "Nous chargeons le matériel ! Il ne reste que quelques secondes."
  },
  it: {
    pdf: "Prepariamo per Lei PDF. Per cortesia, attenda che si carichi.",
    store: "Reindirizziamo al Chrome Store per installare l'app.",
    url: "Carichiamo il materiale! Mancano solo un paio di secondi."
  },
  zh: {
    pdf: "我们为您准备一张PDF文件，请稍候",
    store: "请稍候，正为您跳转至Chrome Store下载软件",
    url: "资料加载中，请稍候"
  }
};
